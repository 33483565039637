<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex flex-row w-full h-full">
      <div v-if="!IS_MIRCO && !data.noSideBar">
        <side-bar />
      </div>
      <div class="right-contain flex flex-col flex-1 !w-0 h-screen" style="width: 0">
        <head-bar v-if="!IS_MIRCO" />
        <!-- Copilot 右侧固定模式下, 因 HeadBar 脱标, 需另外用固定高元素占位 -->
        <div v-if="!IS_MIRCO && copilotDisplayMode === COPILOT_DISPLAY_MODE.FIXED_RIGHT" class="w-full h-[56px] bg-transparent"></div>
        <!-- 主要的业务代码框 -->
        <div
          v-if="data.showBreadcrumb"
          class="pacvue-breadcrumb z-[1000] flex flex-row items-center justify-between w-full h-[56px] py-0 px-6 bg-[#fff] shadow-[0_4px_24px_0_rgba(0,0,0,.06)]"
          :class="{ [className]: className }"
        >
          <!-- 若有自定义左侧面包屑 -->
          <template v-if="BreadcrumbLeft">
            <div ref="breadcrumbLeftWrapperRef" class="flex flex-row flex-1 w-full">
              <BreadcrumbLeft />
              <!-- 加入收藏夹 -->
              <template v-if="data.menuMeta[0] && allRoutePaths && (allRoutePaths.includes(route.path) || allRoutePaths.includes(route.meta?.identicalPath ?? '')) && !noCollect">
                <el-icon
                  v-if="HomePage == route.path || HomePage === route.meta?.identicalPath"
                  :size="20"
                  color="var(--root-menu-highlight)"
                  class="collect cursor-pointer mt-[5px] ml-1"
                  @click="setHome"
                >
                  <PacvueIconHomeSolid />
                </el-icon>
                <el-icon
                  :size="20"
                  :color="judgeInFavorites(data.menuMeta[0].menuId) ? 'var(--root-menu-highlight)' : '#DEDFE3'"
                  class="collect cursor-pointer mt-[6px] ml-1"
                  v-collect="{ menu: data.menuMeta[0], isFavorite: judgeInFavorites(data.menuMeta[0].menuId) }"
                >
                  <PacvueIconStarfill v-if="judgeInFavorites(data.menuMeta[0].menuId)" v-pacvueTooltip="$t('Remove')" />
                  <PacvueIconStar v-else v-pacvueTooltip="$t('Favorite')" />
                </el-icon>
              </template>
            </div>
          </template>
          <!-- 默认左侧面包屑导航 -->
          <template v-else>
            <div ref="breadcrumbLeftWrapperRef" class="default-breadcrumb-left pacvue-breadcrumb-leftlink flex-1 text-[20px] text-[#45464f]">
              <pacvue-breadcrumb v-if="data.menuMeta.length > 1">
                <template #primaryIcon>
                  <component :is="data.icon"></component>
                </template>
                <pacvue-breadcrumb-item v-for="item in data.menuMeta" :key="item.name" :to="{ path: item.path }">{{ item.i18N ? item.i18N : $t(item.name) }}</pacvue-breadcrumb-item>
              </pacvue-breadcrumb>
              <div v-else class="font-bold text-xl flex flex-row">
                {{ data.menuMeta[0]?.i18N }}
                <template v-if="data.menuMeta[0] && allRoutePaths && (allRoutePaths.includes(route.path) || allRoutePaths.includes(route.meta?.identicalPath ?? '')) && !noCollect">
                  <el-icon
                    v-if="HomePage == route.path || HomePage === route.meta?.identicalPath"
                    :size="20"
                    color="var(--root-menu-highlight)"
                    class="collect mt-[4px] ml-1 cursor-pointer"
                    @click="setHome"
                  >
                    <PacvueIconHomeSolid />
                  </el-icon>
                  <el-icon
                    :size="20"
                    :color="judgeInFavorites(data.menuMeta[0].menuId) ? 'var(--root-menu-highlight)' : '#DEDFE3'"
                    class="collect mt-[5px] ml-1 cursor-pointer"
                    v-collect="{ menu: data.menuMeta[0], isFavorite: judgeInFavorites(data.menuMeta[0].menuId) }"
                  >
                    <PacvueIconStarfill v-if="judgeInFavorites(data.menuMeta[0].menuId)" v-pacvueTooltip="$t('Remove')" />
                    <PacvueIconStar v-else v-pacvueTooltip="$t('Favorite')" />
                  </el-icon>
                </template>
              </div>
            </div>
          </template>

          <template v-if="BreadcrumbRight">
            <BreadcrumbRight />
          </template>

          <template v-else>
            <div class="flex flex-row">
              <PacvueDatePickerCustom
                v-if="data.isShowTime"
                :dateRange="appStore.dateRange"
                :isCompare="data.isCompare && data.showCompareCheck"
                :isCompareCustom="appStore.isCompareCustom"
                :compareSummary="data.compareSummary"
                :showCompareCheck="data.showCompareCheck"
                :showSummaryCheck="true"
                :alwaysShowCalendars="false"
                :compareDate="data.compareDate"
                :realTimeClient="realTimeClient"
                :extraOptions="appStore.extraOptions"
                :isRealTimeByPST="appStore.isRealTimeByPST"
                :lifeTimeMode="appStore.lifeTimeMode"
                :latestDateMode="appStore.latestDateMode"
                v-bind="appStore.datepickerOptions"
                :customRangeShowLabel="customRangeShowLabel"
                @update="handleDatePickerUpdate"
              />
            </div>
          </template>
        </div>
        <div class="router-view pacvue-scrollbar" id="APP1">
          <el-scrollbar
            v-pacvueLoading="contentLoading"
            :class="{ 'special-style !overflow-x-auto': copilotDisplayMode === COPILOT_DISPLAY_MODE.FIXED_RIGHT }"
            :wrap-class="copilotDisplayMode === COPILOT_DISPLAY_MODE.FIXED_RIGHT ? 'min-w-[1306px]' : ''"
            ref="scroller"
            @scroll="scroll"
          >
            <!-- 业务页面路由 -->
            <router-view></router-view>
          </el-scrollbar>
        </div>
      </div>
      <div id="copilot-fixed-container" class="transition-all duration-200 bg-[#fff]" :class="copilotDisplayMode === COPILOT_DISPLAY_MODE.FIXED_RIGHT ? 'w-[480px]' : 'w-0'"></div>
    </div>
  </div>
</template>

<script setup>
import { app } from "../frame"
import { provide, reactive, inject, watch, toRefs, ref, onMounted, computed, onUnmounted, nextTick, onBeforeUnmount } from "vue"
import { get as lodashGet, throttle } from "lodash-es"
import HeadBar from "./HeadBar.vue"
import SideBar from "./components/SiderMenu.vue"
import { dayjs } from "@pacvue/element-plus"
import { useAppStore, useUserStore, useCommonStore } from "@/store"
import useFrameStore from "../frameStore"
import { useRoute, useRouter } from "vue-router"
import { path2root, findMenuPath } from "../../utils/menu-tree.js"
import { storeToRefs } from "pinia"
import config from "@~/config.js"
import { DISPLAY_MODE as COPILOT_DISPLAY_MODE } from "../Copilot/constants.js"
import { PLATFORM_NAME_MAPPER } from "../../constants/platform.js"

const customRangeShowLabel = ref({})
const noCollect = ref(config.noCollect)
const IS_MIRCO = ref(import.meta.env.IS_MIRCO)
const copilotDisplayMode = ref(COPILOT_DISPLAY_MODE.FLOAT)
provide("copilotDisplayMode", copilotDisplayMode)
const { BreadcrumbLeft, BreadcrumbRight, className } = toRefs(inject("breadcrumb"))
const scroller = ref(null)
window.scrollerMain = scroller
const route = useRoute()
const router = useRouter()
const appStore = useAppStore()
const userStore = useUserStore()
const commonStore = useCommonStore()
const { HomePage } = storeToRefs(appStore)
const { userSettings } = storeToRefs(userStore)
const breadcrumbLeftWrapperRef = ref(null)
const productLine = window.productline || localStorage.getItem("productline")
if (productLine === "walmart") {
  customRangeShowLabel.value = { "Real Time": $t("Real Time") + "(" + $t("Today") + ")" }
}
let tabTitleInterval = null

const frameStore = useFrameStore()
const setHome = () => {
  appStore.SET_HOMEURL("")
  localStorage.removeItem("storage_" + userStore.user.userName.toLowerCase() + "_homeUrl")
}
const data = ref({
  menuMeta: [],
  icon: "",
  isShowTime: false,
  showBreadcrumb: true,
  date: appStore.dateRange,
  compareDate: appStore.compareTime,
  showCompareCheck: false,
  isCompare: appStore.isCompare,
  compareSummary: appStore.compareSummary,
  realTimeClient: false
})
const realTimeClient = ref(false)

watch(
  () => appStore.isCompare,
  (nv) => {
    data.value.isCompare = nv
  }
)

watch(
  () => appStore.showDateCompare,
  (nv) => {
    data.value.showCompareCheck = nv
  }
)

watch(
  () => appStore.compareTime,
  (v) => {
    data.value.compareDate = v
  }
)

watch(
  () => route,
  (v) => {
    // 路由切换后, 重置滚动条位置
    scroller.value?.setScrollTop(0)
    data.value.isShowTime = v.meta.isShowTime
    data.value.showCompareCheck = v.meta.showCompareCheck
    // 设置日历组件是否显示 DateCompare
    appStore.SET_SHOWDATECAMPRE(v.meta.showCompareCheck)
    const { path, meta, name } = v
    const menu = frameStore.menuMap[path] || frameStore.menuMap[meta?.identicalPath ?? ""]
    data.value.showBreadcrumb = !meta?.noBreadcrumb
    data.value.noSideBar = meta?.noSideBar
    // 能找到 menu, 说明是一级或者二级菜单, 直接显示菜单名称, 不用显示图标
    let realtimePage = ["Ad_Campaign", "Ad_Adgroup", "Ad_Targeting"]
    let isUserConfig = true
    if (productLine == "walmart") {
      isUserConfig = false
      realtimePage = ["Campaign", "Ad_Campaign"]
    }
    if (appStore.realtimePage) {
      realtimePage = appStore.realtimePage
    }
    realTimeClient.value = false
    if (menu) {
      data.value.menuMeta = [menu]
      if (realtimePage.includes(menu.alias) && (userStore?.user?.isRealTimeData || !isUserConfig)) {
        realTimeClient.value = true
      }
    } else {
      // 不在菜单列表中的业务子菜单
      const routes = router.getRoutes()
      // 从当前结点到根菜单节点遍历到的菜单
      let visitedMenuNodes = path2root(routes, name)
      if (visitedMenuNodes.length >= 2) {
        // 查找当前路由节点至根节点的各遍历菜单 icon
        const menuList = frameStore.menu
        let menuPath = findMenuPath(menuList, visitedMenuNodes[0].name)
        menuPath = menuPath.map((m) => ({ ...m, name: m.menuName, path: m.url }))
        visitedMenuNodes = [...menuPath.slice(1), ...visitedMenuNodes.slice(1)]
        data.value.icon = lodashGet(menuPath, "[0].icon", "")
        data.value.menuMeta = visitedMenuNodes
      } else {
        // console.error('请检查下路由配置')
      }
    }
  },
  {
    immediate: true,
    deep: true
  }
)
const handleDatePickerUpdate = (val) => {
  const dateRange = {
    startDate: dayjs(val.startDate).format("MM/DD/YYYY"),
    endDate: dayjs(val.endDate).format("MM/DD/YYYY")
  }
  appStore.SET_DATERANGE(dateRange)
  const dateCompareRange = {
    start: dayjs(val.start).format("MM/DD/YYYY"),
    end: dayjs(val.end).format("MM/DD/YYYY")
  }
  appStore.SET_COMPARETIME(dateCompareRange)
  appStore.SET_ISCOMPARE(val.compare)
  appStore.SET_ISCOMPARECUSTOM(val.isCompareCustom)
  appStore.SET_ISCOMPAREPOP(val.isComparePop)
  appStore.SET_ISCOMPAREYOY(val.isCompareYoy)
  appStore.SET_COMPARESUMMARY(val.compareSummary)
  appStore.SET_LATESTDATEMODE(val.latestDateMode)
  data.value.compareSummary = val.compareSummary
  data.value.compareDate = dateCompareRange
  data.value.isCompare = val.compare
}
const allRoutePaths = ref([])

const scrollFun = () => {
  app.config.globalProperties.$eventHub.emit("scrollWrap", scroller.value)
}
let tt = throttle(scrollFun, 400)
const scroll = () => {
  tt()
}
// 添加 contentLoading
let contentLoading = appStore.contentLoading

watch(
  () => appStore.menuIdItemMap,
  (nv) => {
    let _paths = []
    for (let menuId in nv) {
      nv[menuId].url && _paths.push(nv[menuId].url)
    }
    allRoutePaths.value = _paths
  },
  {
    immediate: true
  }
)

const overwriteTabTitleByBreadcrumbLeftWrapper = ({ titleEl }) => {
  const breadItemsEl = breadcrumbLeftWrapperRef.value?.querySelectorAll(".el-breadcrumb__item")
  if (!breadItemsEl || breadItemsEl.length === 0) {
    const moduleName = breadcrumbLeftWrapperRef.value?.querySelector("div:first-child")?.innerText
    // 有左侧面包屑, 但其内容为空的情形 (不在 MenuMap 中)
    if (!moduleName) {
      const searchParams = new URLSearchParams(location.search)
      // 特殊页面1: Share Link
      if (location.href.includes("/Share/") || searchParams.has("shareId") || searchParams.has("AMCT")) {
        titleEl.innerHTML = `${PLATFORM_NAME_MAPPER[productLine]} - ${$t("amskey488")}`
      }
    } else {
      // 正常的模块 (在 MenuMap 中)
      // 截取最后一个>后面的文案内容，作为实际展示的title
      const lastModuleName = moduleName.includes(">") ? moduleName.split(">").pop().trim() : moduleName.trim()
      titleEl.innerHTML = `${PLATFORM_NAME_MAPPER[productLine]} - ${lastModuleName}`
    }
  } else {
    const moduleName = breadItemsEl[breadItemsEl.length - 1]?.innerText
    // 多路径面包屑
    titleEl.innerHTML = `${PLATFORM_NAME_MAPPER[productLine]} - ${moduleName}`
  }
}

const overwriteTabTitleByCustomBreadcrumbLeft = ({ titleEl }) => {
  const customBreadcrumbLeftEl = document.querySelector(".custom-breadcrumb-left")
  if (customBreadcrumbLeftEl?.innerText) {
    clearInterval(tabTitleInterval)
    tabTitleInterval = null
    titleEl.innerHTML = `${PLATFORM_NAME_MAPPER[productLine]} - ${customBreadcrumbLeftEl.innerText}`
  }
}

// 监听自定义面包屑 / 路由的改变, 从而改变 tab title 短杠后的模块名称
watch(
  [BreadcrumbLeft, route, breadcrumbLeftWrapperRef],
  () => {
    if (tabTitleInterval) {
      clearInterval(tabTitleInterval)
      tabTitleInterval = null
    }
    setTimeout(() => {
      if (tabTitleInterval) {
        clearInterval(tabTitleInterval)
        tabTitleInterval = null
      }
    }, 5000)
    const dynamicTitleEl = document.getElementById("dynamic-title")
    if (route.meta?.i18N) {
      // 优先使用 route meta 中的信息
      dynamicTitleEl.innerHTML = `${PLATFORM_NAME_MAPPER[productLine]} - ${route.meta?.i18N}`
    } else if (breadcrumbLeftWrapperRef.value) {
      nextTick(() => {
        overwriteTabTitleByBreadcrumbLeftWrapper({ titleEl: dynamicTitleEl })
        if (!tabTitleInterval) {
          tabTitleInterval = setInterval(() => {
            if (!breadcrumbLeftWrapperRef.value) return
            overwriteTabTitleByBreadcrumbLeftWrapper({ titleEl: dynamicTitleEl })
          }, 1000)
        }
      })
    } else {
      // 不存在面包屑的页面, 例如登录页
      dynamicTitleEl.innerHTML = PLATFORM_NAME_MAPPER[productLine]
      // 若有完全定制的面包屑
      overwriteTabTitleByCustomBreadcrumbLeft({ titleEl: dynamicTitleEl })
      if (!tabTitleInterval) {
        tabTitleInterval = setInterval(() => {
          overwriteTabTitleByCustomBreadcrumbLeft({ titleEl: dynamicTitleEl })
        }, 1000)
      }
    }
  },
  { immediate: true }
)

const judgeInFavorites = (menuId) => {
  return userSettings.value?.userFavourite?.length > 0 && userSettings.value.userFavourite.includes(menuId)
}

onMounted(() => {
  app.config.globalProperties.$eventHub.on("copilotDisplayModeChange", (mode) => {
    document.body.style.setProperty("min-width", mode === COPILOT_DISPLAY_MODE.FIXED_RIGHT ? "unset" : "1445px")
    copilotDisplayMode.value = mode
  })
})

onBeforeUnmount(() => {
  document.getElementById("dynamic-title").innerHTML = PLATFORM_NAME_MAPPER[productLine]
})

onUnmounted(() => {
  app.config.globalProperties.$eventHub.off("copilotDisplayModeChange")
})
</script>

<style lang="scss" scoped>
.right-contain {
  .router-view {
    flex: 1;
    background-color: var(--el-color-bg-body);
  }
}
:deep(.el-scrollbar) {
  &.special-style .el-scrollbar__bar.is-vertical {
    display: none;
  }
}
</style>
